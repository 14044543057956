import * as Sentry from '@sentry/browser'

const sentryEnvironments = ['production', 'staging', 'feature']

if (sentryEnvironments.includes(window.environment)) {
  Sentry.init({
    dsn: 'https://6c2d907193364be6b2c535b5df7ba868@o45347.ingest.sentry.io/4504763682324480',
    integrations: [Sentry.browserTracingIntegration()],
    environment: window.environment,
    ignoreErrors: [],

    release: process.env.PENDING_RELEASE_VERSION || process.env.RELEASE_VERSION,
    tracesSampleRate: parseFloat(process.env.SENTRY_TRACES_SAMPLE_RATE || '0.1')
  })
}
