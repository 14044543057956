// Libraries
import _every from 'lodash/every'
import _some from 'lodash/some'
import _keys from 'lodash/keys'
import _find from 'lodash/find'
import moment, { localMoment, localMomentInUTC } from 'shared/lib/moment'

// Shared
import SettingUtils from 'shared/utils/settings'
import Collection from 'shared/lib/orm/collection'

const dayMap = {
  0: ['sun', 'weekend', 'all'],
  1: ['mon', 'weekday', 'all'],
  2: ['tue', 'weekday', 'all'],
  3: ['wed', 'weekday', 'all'],
  4: ['thu', 'weekday', 'all'],
  5: ['fri', 'weekday', 'all'],
  6: ['sat', 'weekend', 'all']
}

// Create a moment instance which take the company timezone into account
const getNow = (state) => {
  const companyUtcOffset = state.client && state.client.company && state.client.company.get('utc_offset')

  let now

  if (companyUtcOffset) {
    const userUtcOffset = localMoment().utcOffset()
    // Get the difference in minutes between user and company timezone
    const differenceInMinutes = companyUtcOffset - userUtcOffset
    // Add the difference to the time the user is currently in
    // so we perform calculations against the companies time in user local time
    const nowInCompanyTimezone = localMomentInUTC().add(differenceInMinutes, 'minutes')

    // Fallback to now if users time is ahead of company time
    now = (differenceInMinutes < 0) ? localMomentInUTC() : nowInCompanyTimezone
  } else {
    now = localMomentInUTC()
  }

  return now
}

const isAfterLagTime = (date, now, dateOnly) => {
  // Make sure we fulfill lag time requirements
  if (SettingUtils.get('store.use_order_lag_time')) {
    if (
      (SettingUtils.get('store.use_times') || SettingUtils.get('store.order_lag_time_interval') === 'hours') &&
      !dateOnly
    ) {
      if (date.diff(
        now,
        SettingUtils.get('store.order_lag_time_interval')
      ) < SettingUtils.get('store.order_lag_time_value')) return false
    } else {
      // Times disabled, just compare the dates
      const nowPlusLag = now.clone()
        .startOf('day')
        .add(
          SettingUtils.get('store.order_lag_time_value'),
          SettingUtils.get('store.order_lag_time_interval')
        )
        .subtract(1, 'day')

      return date.isAfter(nowPlusLag, 'day')
    }
  }

  // No lagtime so always true
  return true
}

export const getOperatingRules = (state, options = {}) => {
  // Grab operatingRules from state
  const dataTypes = [state.settings.get('store.period_type')]
  const operatingRulesCollection = options.operatingRules || state.orm.operating_rules

  if (state.settings.get('store.use_business_hours')) {
    dataTypes.push('hours')
  }

  if (state.settings.get('store.use_away_mode')) {
    dataTypes.push('away')
  }

  return operatingRulesCollection && operatingRulesCollection.getAll({ data_type: dataTypes })
}

const isInAwayPeriod = (date, operatingRules) => {
  const awayRules = operatingRules.getAll({ data_type: 'away' }).toArray()

  if (awayRules.length === 0) return false

  const notInAway = _every(awayRules, operatingRule => {
    const range = moment.range(
      moment(operatingRule.data.away.from).startOf('day'),
      moment(operatingRule.data.away.till).endOf('day')
    )

    return !range.contains(date)
  })

  return !notInAway
}

const isInPast = (date, now, dateOnly = false) => {
  if (dateOnly) {
    const adjustedDate = date.clone().startOf('day')
    const adjustedNow = now.clone().startOf('day')

    if (adjustedDate.isBefore(adjustedNow)) return true
  } else {
    if (date.isBefore(now)) return true
  }

  return false
}

export const isWithinOpeningHours = (date, operatingRules, skipTimeslots, dateOnly, allowPast = false) => {
  if (!date) return false

  const periodType = SettingUtils.get('store.period_type')

  // Check if we use fixed timeslots, if so check if there are any timeslots available for given date.
  // This is like the reserved behavior of the operating hours. We should skip the rest of the check.
  if (periodType === 'timeslot_fixed' && !skipTimeslots && !dateOnly) {
    return operatingRuleUtils.fixedTimeslotsForDate(
      operatingRules.getAll({ data_type: 'timeslot_fixed' }), date,
      { allowPast }
    ).size() > 0
  }

  // No operating hours set, everything is available!
  if (operatingRules.getAll({ data_type: 'hours' }).size() === 0) return true

  // Make a map of each day type for easy access
  const operatingHoursPerType = {}

  operatingRules.getAll({ data_type: 'hours' }).forEach(operatingRule => {
    const dayType = _keys(operatingRule.data)[0]

    operatingHoursPerType[dayType] = operatingHoursPerType[dayType] || []
    operatingHoursPerType[dayType].push(operatingRule)
  })

  let operatingHours = null
  const dayIndex = date.day()
  const dayTypes = dayMap[dayIndex]

  // Grab the operating hours for the day in date
  // If every Weekday is set, the rule for Monday should have priority for Mondays
  dayTypes.forEach(dayType => {
    operatingHours = operatingHours || operatingHoursPerType[dayType]
  })

  let isOpen

  if (operatingHours) {
    if (dateOnly) {
      // We have operating hours today so it must be open
      isOpen = true
    } else {
      // Check by time
      isOpen = _some(operatingHours, (operatingRule) => {
        const dayType = _keys(operatingRule.data)[0]
        const from = operatingRule.data[dayType].from
        const till = operatingRule.data[dayType].till

        const dateString = date.format('YYYY-MM-DD')

        const openRange = moment.range(
          moment(`${dateString} ${from}`, 'YYYY-MM-DD HH-mm'),
          moment(`${dateString} ${till}`, 'YYYY-MM-DD HH-mm')
        )

        return openRange.contains(date)
      })
    }
  } else {
    // No operating hours found for this day, so its not open
    isOpen = false
  }

  return isOpen
}

const operatingRuleUtils = {
  /*
  * Filters (fixed) timeslots for a specific date.
  */
  fixedTimeslotsForDate (timeslots, date, options = {}) {
    if (date) {
      date = moment(date)
    } else {
      date = moment()
    }

    const dayIndex = date.day()
    const dayTypes = dayMap[dayIndex]

    const groupedTimeslots = timeslots.groupBy((timeslot) => _keys(timeslot.data)[0])

    for (const day of dayTypes) {
      const foundTimeSlots = groupedTimeslots.get(day)

      if (foundTimeSlots) {
        // Only return slots that are actually selectable
        return foundTimeSlots.filter((timeslot) => {
          const key = _keys(timeslot.data)[0]
          const from = timeslot.data[key].from
          const hour = from.split(':')[0]
          const minute = from.split(':')[1]

          const time = date.clone().set({
            hour,
            minute
          })

          return this.isTimeSelectable(time, { ...options, skipTimeslots: true })
        })
      }
    }

    return new Collection()
  },
  /*
  * Checks of given time is available according to opening hours.
  *
  * @example
  *   isTimeSelectable(moment('01-01-2018 09:00'), { skipTimeSlots: true })
  */
  isTimeSelectable (date, options = {}) {
    const state = (window.store || window.booqableStore).getState()
    const now = getNow(state)

    if (!options.allowPast) {
      // Date is in the past, no time available
      const dateOnly = !SettingUtils.get('store.use_times') && SettingUtils.get('orders.start_type') !== 'fixed'

      if (isInPast(date, now, dateOnly)) return false

      // Make sure to respect prevent last minute reservations setting
      if (!isAfterLagTime(date, now, options.dateOnly)) return false
    }

    // Only perform expensive and/or cached calculations
    // when we are sure date is not in the past

    this._timeAvailableCache = this._timeAvailableCache || {}

    const cacheKey = options.dateOnly ? date.format('YYYY-MM-DD') : date.format()

    if (this._timeAvailableCache[cacheKey] !== undefined) {
      return this._timeAvailableCache[cacheKey]
    }

    // Check if the day is selectable at all, if not then there's no need to check for each time
    if (!options.dateOnly && !this.isTimeSelectable(date, { ...options, dateOnly: true })) {
      // Cache result
      this._timeAvailableCache[cacheKey] = false

      return false
    }

    const operatingRules = getOperatingRules(state, options)

    // No operating hours set, everything is available!
    if (!operatingRules || operatingRules.size() === 0) return true

    // Check if there is any away period first, these are full days (from/till)
    // Return false if there is any away period, no need to continue
    if (isInAwayPeriod(date, operatingRules)) {
      // Cache result
      this._timeAvailableCache[cacheKey] = false

      return false
    }

    const isOpen = isWithinOpeningHours(
      date, operatingRules, options.skipTimeslots, options.dateOnly, options.allowPast
    )

    // Cache result
    this._timeAvailableCache[cacheKey] = isOpen

    return isOpen
  },
  firstTimeSelectable () {
    const time = localMomentInUTC()

    const minute = time.minute()

    // Round per 15 minutes
    if (minute === 0) {
      // Do nothing
    } else if (minute < 15) {
      time.set('minute', 15)
    } else if (minute < 30) {
      time.set('minute', 30)
    } else if (minute < 45) {
      time.set('minute', 45)
    } else if (minute <= 59) {
      time.set('minute', 0)
      time.add(1, 'hour')
    }

    while (!this.isTimeSelectable(time)) {
      // Accommodations for being in an away period
      // prevents hanging when in long away periods
      const awayPeriod = getCurrentAwayPeriod(time)

      if (awayPeriod) {
        const endOfAway = moment(awayPeriod.data.away.till)

        time.set({ year: endOfAway.year(), month: endOfAway.month(), date: endOfAway.date() })
        time.endOf('day')
      }

      time.add(15, 'minutes')
    }

    return time
  },
  isWithinOpeningHours
}

export default operatingRuleUtils

export const getCurrentAwayPeriod = (date) => {
  const state = (window.store || window.booqableStore).getState()
  const operatingRules = getOperatingRules(state)
  const awayRules = operatingRules.getAll({ data_type: 'away' }).toArray()

  if (awayRules.length === 0) return false

  const currentAway = _find(awayRules, operatingRule => {
    const range = moment.range(
      moment(operatingRule.data.away.from).startOf('day'),
      moment(operatingRule.data.away.till).endOf('day')
    )

    return range.contains(date)
  })

  return currentAway
}
