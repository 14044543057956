// React
import React, { Component } from 'react'
import { createRoot } from 'react-dom/client'

// Libraries
import { ThemeProvider } from 'styled-components'
import { Provider } from 'react-redux'

// Components

// Shared
import store from 'login/redux/store'
import theme from 'ui/themes/app'
import Login from './login'
import loginActions from 'login/redux/actions/login'

class App extends Component {
  static displayName = 'BooqableLogin'

  render () {
    return (
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <Login />
        </ThemeProvider>
      </Provider>
    )
  }
}

window.onload = () => {
  const container = document.getElementById('root')

  if (container) {
    // Setup oauth data if present
    let data = container.getAttribute('data')

    data = data && JSON.parse(data)
    loginActions.initializeData(data)

    const root = createRoot(container)
    const component = React.createElement(App, {})

    root.render(component)
  }

  // Track pageview

  const analytics = window.analytics

  analytics && analytics.page && analytics.page()
}
