import styled, { css } from 'styled-components'
import { color, fontSize } from 'ui/themes/utils'

import I from 'ui/elements/I'
import Span from 'ui/elements/Span'

export const Loader = styled(Span)`
  font-size: ${fontSize()};

  ${I} {
    ${props => props.color && css`
      color: ${props => color(props.color)} !important;
    `}

    width: ${fontSize()};
    height: ${fontSize()};
  }
`
