import styled, { css } from 'styled-components'
import { color, fontSize, injectModifiers } from 'ui/themes/utils'
import modifiers from 'ui/themes/modifiers'
import A from 'ui/elements/A'
import I from 'ui/elements/I'

export const Link = styled(A)`
  cursor: pointer;
  align-self: center;
  font-size: ${fontSize()};

  ${(props) => props.iconOnly && css`
    display: inline-block;
  `}

  ${(props) => props.block && css`
    display: block;
    align-self: left;
  `}

  ${(props) => props.color === 'primary' && css`
    color: ${color('Primary/Base')};
  `}

  ${(props) => props.color === 'dark' && css`
    color: ${color('Text/Primary')};
  `}

  ${(props) => props.color === 'white' && css`
    color: ${color('Text/Inverted')};

    &:hover {
      color: ${color('Text/Inverted')};
      opacity: 0.6;
    }

    &:focus {
      color: ${color('Text/Inverted')};
    }
  `}

  ${(props) => props.iconColor && css`
    ${I} {
      color: ${color(props.iconColor)};
    }
  `}

  ${(props) => props.color === 'danger' && css`
    color: ${color('Danger/Base')};
  `}

  ${(props) => props.underline && css`
    text-decoration: underline;

    &:hover {
      text-decoration: underline;
    }
  `}

  ${({ noDecoration }) => noDecoration && css`
    text-decoration: none;
  `}

  ${(props) => props.target === '_blank' && !props.noBlankIcon && css`
    ${I} {
      &:last-child {
        margin-left: 0.2em;
        display: inline-block;
        font-size: ${fontSize('sm')};;
        vertical-align: baseline;
        box-sizing: border-box;
      }
    }
  `}

  ${I} {
    &:first-child {
      margin-right: 0.2em;
    }
  }

  ${props => props.iconAppend && css`
    ${I} {
      &:last-child {
        margin-top: 4px;
        float: right;
      }
    }
  `}

  ${props => props.disabled && css`
    cursor: default;
    opacity: 0.6;
    pointer-events: none;
    color: ${color('Text/Secondary')};
  `}

  ${({ theme }) => injectModifiers(modifiers(theme))}
`
