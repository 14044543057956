// React
import React, { createElement } from 'react'
import PropTypes from 'prop-types'

// Libraries
import { useTranslation, Trans } from 'react-i18next'

// Components
import Skeleton from 'ui/blocks/Skeleton'

/**
 * Higher order compomnent to get the `t` function from `react-i18next`,
 * but with a skeleton fallback when the translations are not ready yet.
 *
 * Prefer using the `useTranslation` hook instead of this HOC for functional components.
 */
export const withTranslation = (namespace) => (WrappedComponent) => {
  const I18nextWithTranslation = (props) => {
    const { tNamespace } = props

    const [t, i18n, ready] = useTranslation(tNamespace || namespace, { useSuspense: false })

    const tOrLoadingBar = (key, options = {}) => {
      return ready ? t(key, options) : <Skeleton key={key} namespace={namespace} ready={ready} string={key} />
    }

    const tOrEmptyString = (key, options = {}) => {
      return ready ? t(key, options) : ''
    }

    const TransComponent = (transProps) => {
      return ready
        ? <Trans t={t} {...transProps} />
        : <Skeleton key={transProps.i18nKey} namespace={namespace} ready={ready} string={transProps.i18nKey} />
    }

    TransComponent.propTypes = {
      i18nKey: PropTypes.string
    }

    return createElement(WrappedComponent, {
      ...props, Trans: TransComponent, t: tOrLoadingBar, tOrEmptyString, i18n, tReady: ready
    })
  }

  I18nextWithTranslation.propTypes = {
    tNamespace: PropTypes.string
  }

  return I18nextWithTranslation
}
