import styled, { css } from 'styled-components'
import { color } from 'ui/themes/utils'

import I from 'ui/elements/I'

export const Icon = styled(I)`
  ${props => props.hover && css`
    cursor: pointer;
  `}

  ${props => {
    if (!props.color) return

    return css`
      color: ${color(props.color)};
    `
  }}
`
