import Immutable from 'immutable'
import isEmpty from 'lodash/isEmpty'
import transform from 'lodash/transform'

class Collection extends Immutable.Record({
  _objects: Immutable.OrderedMap({}),
  _temporaryObjects: Immutable.OrderedMap({}),
  _object: null,
  isLoadingResource: false,
  isLoadingCollection: false,
  createdAt: null
}) {
  static initClass () {
    // Persisted mutations
    const persistedMutations = ['filter', 'sortBy', 'reverse']

    persistedMutations.forEach(method => {
      return (method => {
        this.prototype[method] = function () { return this.set('_objects', this._objects[method](...arguments)) }

        return this.prototype[method]
      })(method)
    })

    // Delegate to objects list
    const listMethods = ['forEach', 'map', 'indexOf']

    listMethods.forEach(method => {
      return (method => {
        this.prototype[method] = function () { return this._objects.toList()[method](...arguments) }

        return this.prototype[method]
      })(method)
    })

    // Delegate to objects
    const objectMethods = ['toJSON', 'toObject', 'toJS', 'toArray', 'count', 'toList', 'first', 'isEmpty']

    objectMethods.forEach(method => {
      return (method => {
        this.prototype[method] = function () { return this._objects[method](...arguments) }

        return this.prototype[method]
      })(method)
    })
  }

  constructor (object) {
    super({ _object: object, createdAt: Date.now() })
  }

  // Immutable's way of checking 2 collections.
  //
  // @example
  //   collection.is(collection2)
  //
  is (collection) {
    return Immutable.is(this._objects, collection._objects)
  }

  strictEqual (collection) {
    return this._objects.strictEqual(collection._objects)
  }

  // Hydrate data with delicious fresh data.
  //
  // @example
  //   collection.hydrate([...])
  //
  hydrate (data) {
    if (isEmpty(data)) { return this }

    const mergeObjects = {}

    for (const i in data) {
      const attributes = data[i]
      const id = attributes.id
      const existingObject = this.getById(id)

      if (existingObject) {
        // Object exists, merge new data
        mergeObjects[id] = existingObject.hydrate(attributes)
      } else {
        // Object does not yet exist, just return it
        mergeObjects[id] = new this._object(attributes)
      }
    }

    // Merge and return
    return this._setObjects(this._objects.merge(mergeObjects))
  }

  // Invalidates the built-up cache by checking time passed and collection size.
  //
  // @example
  //   collection.invalidateCache()
  //   collection.invalidateCache({ sizeLimit: 500 })
  //   collection.invalidateCache({ expiryTimeInMinutes: 5 })
  //
  invalidateCache (options = {}) {
    if (!options.expiryTimeInMinutes) { options.expiryTimeInMinutes = 15 }
    if (!options.sizeLimit) { options.sizeLimit = 100 }

    const timeExpired = (Date.now() - this.createdAt) >= (options.expiryTimeInMinutes * (60 * 1000))
    const sizeExceeded = this.size() > options.sizeLimit

    if (timeExpired || sizeExceeded) {
      // Return new empty collection
      let collection = this

      collection = collection.set('_objects', Immutable.OrderedMap({}))
      collection = collection.set('createdAt', Date.now())

      return this._persist(collection)
    } else {
      return this
    }
  }

  // Lookup: Find all objects by given attributes and values.
  //
  // @example
  //   collection.getAll(employee_id: 1)
  //
  getAll (matchAttributes) {
    if (matchAttributes) {
      const results = this._objects.filter(function (object) {
        let match = true

        for (const key in matchAttributes) {
          const value = matchAttributes[key]

          if (value instanceof Array || (value?.toArray && value?.includes)) {
            if (!value.includes(object[key])) { match = false }
          } else {
            if (object[key] !== value) { match = false }
          }
        }

        return match
      })

      return this.set('_objects', results)
    } else {
      return this
    }
  }

  // Lookup: Finds first by given attributes and values.
  //
  // @example
  //   collection.getFirst(slug: 'imac')
  //
  getFirst (matchAttributes) {
    return this.getAll(matchAttributes).first()
  }

  // Lookup: Find record by id.
  // When an array is given it will return the matching objects in that order.
  //
  // @example
  //   collection.getById(1)
  //   collection.getById([2, 1, 3])
  //
  getById (id) {
    if (id instanceof Array) {
      return this.getAll({ id }).sortBy(o => id.indexOf(o.id))
    } else if (id?.toArray) {
      // Some kind of ImmutableJS collection, or an Iterator.
      return this.getById(id.toArray())
    } else if (id) {
      return this._objects.get(id.toString()) || this._temporaryObjects.get(id.toString())
    } else {
      return null
    }
  }

  // Simple sort by attribute.
  //
  // @example
  //   collection.sort('id', 'DESC')
  //
  sort (attribute, direction = 'ASC') {
    const naturalCompare = function (a, b) {
      if ((typeof a !== 'string') && (typeof b !== 'string')) {
        if (a < b) { return -1 } else if (a > b) { return 1 } else { return 0 }
      }

      const ax = []
      const bx = []

      a.replace(/(\d+)|(\D+)/g, function (_, $1, $2) {
        ax.push([
          $1 || Infinity,
          $2 || ''
        ])
      })
      b.replace(/(\d+)|(\D+)/g, function (_, $1, $2) {
        bx.push([
          $1 || Infinity,
          $2 || ''
        ])
      })
      while (ax.length && bx.length) {
        const an = ax.shift()
        const bn = bx.shift()
        const nn = (an[0] - (bn[0])) || an[1].localeCompare(bn[1])

        if (nn) {
          return nn
        }
      }

      return ax.length - (bx.length)
    }

    const sortedObjects = this._objects.sort((a, b) => {
      if (direction === 'DESC') {
        return naturalCompare(b[attribute], a[attribute])
      } else {
        return naturalCompare(a[attribute], b[attribute])
      }
    })

    return this.set('_objects', sortedObjects)
  }

  pluck (attribute) {
    return this.map(o => o[attribute]).toArray()
  }

  /**
   * Returns array of objects with the attributes from the collection.
   *
   * @example
   *   locations.pluckObject({ label: 'name', value: 'id' })
   *
   * @returns
   *   [{ label: 'Leeuwarden', value: 1 }, { label: 'Amsterdam', value: 2 }]
   */
  pluckObject (attributesObject) {
    return this.map((o) => {
      return transform(attributesObject, (result, value, key) => {
        result[key] = o[value]
      })
    }).toJS()
  }

  // Merges objects with given data.
  //
  merge (object) {
    return this._setObjects(this._objects.merge(object))
  }

  groupBy (func) {
    const groupById = {}

    this._objects.forEach(object => {
      const value = func(object)

      if (!groupById[value]) { groupById[value] = [] }

      return groupById[value].push(object.id)
    })

    let map = Immutable.OrderedMap()

    for (const key in groupById) {
      const ids = groupById[key]

      map = map.set(key, this.getById(ids))
    }

    return map
  }

  // Add object.
  //
  add (id, attributes, options = {}) {
    if (options.temporary) {
      attributes.temporary = true

      // Temporary object, add to _temporaryObjects map
      return this._persist(this.setIn(['_temporaryObjects', id.toString()], new this._object(attributes)))
    } else {
      const data = {}

      data[`${id}`] = attributes

      return this.hydrate(data)
    }
  }

  // Update object with given id.
  //
  update (id, attributes) {
    const object = this.getById(id)

    if (!object) { return this.add(id, attributes) }

    if (object.temporary) {
      return this._persist(this.setIn(['_temporaryObjects', id.toString()], object.hydrate(attributes)))
    } else {
      return this._setObjects(this._objects.set(id.toString(), object.hydrate(attributes)))
    }
  }

  // Delete object with given id.
  //
  delete (id) {
    const object = this.getById(id)

    if (object) {
      if (object.temporary) {
        return this._persist(this.deleteIn(['_temporaryObjects', id.toString()]))
      } else {
        return this._setObjects(this._objects.delete(id.toString()))
      }
    } else {
      return this
    }
  }

  // Set objects, this will return a new instance because it is immutable.
  // It will also set the collection on the object.
  //
  _setObjects (objects) {
    return this._persist(this.set('_objects', objects))
  }

  // Persists collection on the object.
  //
  _persist (collection, expire = false) {
    this._object._collection = collection

    return this._object._collection
  }

  size () {
    return this._objects.size
  }

  get length () {
    return this.size()
  }
}
Collection.initClass()

export default Collection
