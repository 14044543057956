import store from '../store'
import { List } from 'immutable'
import axios from 'axios'

const LoginActions = {
  initializeData: (data) => {
    return store.dispatch({
      type: 'INITIALIZE_DATA',
      payload: { data }
    })
  },

  lookupCompaniesByEmail: (email) => {
    const url = `${window.booqableProtocol}://login.${window.booqableUrl}/api/1/companies_by_email`

    return axios.post(url, { email })
      .then(response => {
        return store.dispatch({
          type: 'COMPANIES_FETCH_SUCCESS',
          payload: {
            email,
            companies: List(response.data)
          }
        })
      })
  },

  selectCompany: (company) => {
    return store.dispatch({
      type: 'SELECTED_COMPANY',
      payload: { selectedCompany: company }
    })
  },

  reset: () => {
    return store.dispatch({
      type: 'RESET',
      payload: {
        selectedCompany: null,
        email: null,
        companies: List(),
        password: null
      }
    })
  },

  isAuthenticated: company => {
    const url = `${window.booqableProtocol}://${company}.${window.booqableUrl}/api/1/session`

    return axios.get(url, { withCredentials: true })
  }
}

export default LoginActions
